

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import ErrorAlert from '@/components/ErrorAlert.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import { CreateFinalInvoice } from '../../../types';
import { CREATE_FINAL_INVOICE, FETCH_FINAL_INVOICE } from '@/modules/listOfAssets/store';
import { ApiResponse } from '@/components/types';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    ErrorAlert,
    DatePicker
  }
})
export default class FinalInvoiceDialog extends Vue {
  @Prop({ type: Boolean, default: false }) icon!: boolean;
  @Prop({ type: String }) listOfAssetsId!: string;
  @Prop({ type: String }) start!: string;
  @Prop({ type: String }) end?: string;

  @ListOfAssets.Action(CREATE_FINAL_INVOICE) createFinalInvoice!: (params: CreateFinalInvoice) => Promise<ApiResponse>;
  @ListOfAssets.Action(FETCH_FINAL_INVOICE) fetchFinalInvoice!: (listOfAssetsId: string) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;

  file: string = '';

  value: CreateFinalInvoice = {
    list_of_assets_id: this.listOfAssetsId,
    start: this.start,
    end: this.end || format(Date.now(), 'yyyy-MM-dd'),
    with_receiver: true
  };

  get download() {
    return `Schlussrechnung-${this.value.end}.pdf`;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog === true) {
      this.value.start = this.start;
      this.value.end = this.end || format(Date.now(), 'yyyy-MM-dd');

      return;
    }

    setTimeout(() => {
      if (this.file !== '') {
        URL.revokeObjectURL(this.file);
      }

      this.error = null;
      this.file = '';
      this.loading = false;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.loading = true;

    const { error } = await this.createFinalInvoice(this.value);

    if (error) {
      this.loading = false;
      this.error = error;

      return;
    }

    const { content, error: fetchError } = await this.fetchFinalInvoice(this.listOfAssetsId);

    this.loading = false;

    if (content) {
      this.file = URL.createObjectURL(content);
    }

    if (fetchError) {
      this.error = fetchError;
    }
  }
}
